import './config'
import Vue from 'vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueLoading from 'vuejs-loading-plugin'
import Notifications from 'vue-notification'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import Storage from 'vue-ls'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/utils/filter'
import './reset.css'
import './icon.css'
import './errorImg.css'
import 'swiper/dist/css/swiper.css'

// 监听图片加载错误事件
document.addEventListener(
  'error',
  e => {
    let target = e.target
    const tagName = target.tagName || ''
    const curTimes = Number(target.dataset.retryTimes) || 0
    if (tagName.toLowerCase() === 'img') {
      if (curTimes >= 1) {
        target.classList.remove('error')
        target.classList.add('error')
      } else {
        target.dataset.retryTimes = curTimes + 1
        // eslint-disable-next-line no-self-assign
        target.src = target.src
      }
    }
    target = null
  },
  true
)

Vue.use(VueDOMPurifyHTML)
Vue.use(Notifications)
Vue.use(VueAwesomeSwiper)
Vue.use(VueLoading, {
  dark: true, // default false
  text: '加载中....', // default 'Loading'
  loading: false, // default false
  background: 'rgb(255,255,255, 0.6)', // set custom background
  classes: ['shamp-loading'] // array, object or string
})

Vue.use(Storage, {
  namespace: 'pro__', // key prefix
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'local' // storage name session, local, memory
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
