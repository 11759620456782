<template>
  <div v-show="show" class="go-top" @click="goTop">
  </div>
</template>

<script>
import { throttle } from 'lodash'
export default {
  name: 'GoTop',
  data () {
    return {
      show: (document.documentElement.scrollTop || document.body.scrollTop) !== 0
    }
  },
  mounted () {
    window.addEventListener('scroll', throttle(this.handleScroll, 500))
  },
  unmounted () {
    window.removeEventListener('scroll', throttle(this.handleScroll, 500))
  },
  methods: {
    handleScroll () {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 0) {
        this.show = true
      } else {
        this.show = false
      }
    },
    goTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style scoped lang="less">
.go-top {
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-image: url("../assets/up.jpg");
  border: 1px solid #dcdcdc;
}
.go-top:hover {
  background-color: hsla(0, 0%, 71%, 0.1);
}
.go-top i {
  font-size: 20px;
  color: #333;
}
</style>
