<template>
    <div class="container">
        <a href="javascript:void(0);" class="button"  @click="onClick">了解更多 ></a>
    </div>
</template>

<script>
export default {
  name: 'LearnMoreBtn',
  props: {
    url: String
  },
  methods: {
    onClick () {
      this.$router.push({ path: this.url })
    }
  }
}
</script>

<style scoped lang="less">
.container {
    padding-top: 16px;
    padding-bottom: 40px;
    text-align: center;
}
.button {
    font-size: 16px;
    color: #333;
    padding: 9px 40px;
    margin: auto;
    border: 1px #d8d8d8 solid;
    border-radius: 50px;
}
</style>
