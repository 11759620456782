<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
            <div class="modal-container" :style="{ width: this.width }">

                <div class="modal-header">
                    <slot name="header">
                        <h3 class="modal-title">{{ title }}</h3>
                        <img class="modal-close" src="../assets/close.png" @click="$emit('close')"/>
                    </slot>
                </div>

                <div class="modal-body">
                    <slot name="body"></slot>
                </div>

                <div class="modal-footer">
                  <slot name="footer"> </slot>
                </div>
            </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    title: String,
    width: String
  }
}
</script>

<style scoped lang="less">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  margin: 0px auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.modal-header {
    position: relative;
    padding: 0 20px;
    height: 80px;
    background-color: #f6f6f6;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.modal-title {
    margin: 0;
    line-height: 80px;
    font-weight: 500;
    color: #333;
    font-size: 30px;
    text-align: center;
}
.modal-close {
    position: absolute;
    right: 27px;
    top: 20px;
    width: 40px;
    height: 40px;
    cursor: pointer;
}
.modal-body {
  margin: 20px 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>
