import Vue from 'vue'
// 设置全局API_BASE_URL
Vue.prototype.API_BASE_URL = process.env.VUE_APP_API_BASE_URL
Vue.prototype.SYSTEM_URL = process.env.VUE_APP_SYSTEM_URL
Vue.prototype.API_SITE_URL = process.env.VUE_APP_SITE_URL
window._CONFIG.domianURL = Vue.prototype.API_BASE_URL

window._CONFIG.staticDomainURL = Vue.prototype.API_BASE_URL + '/sys/common/static'

window._CONFIG.siteURL = Vue.prototype.API_SITE_URL

window._CONFIG.systemURL = Vue.prototype.API_BASE_URL
