<template>
  <div class="nav-contianer">
    <AppLink
      class="nav-link"
      active-class="nav-active"
      inactive-class="nav-inactive"
      to="/home"
    >
      首页
    </AppLink>
    <AppLink
      class="nav-link"
      active-class="nav-active"
      inactive-class="nav-inactive"
      to="/products"
    >
      服务
    </AppLink>
    <AppLink
      class="nav-link"
      active-class="nav-active"
      inactive-class="nav-inactive"
      to="/articles"
    >
      必读
    </AppLink>
    <AppLink
      class="nav-link"
      active-class="nav-active"
      inactive-class="nav-inactive"
      to="/activitys"
    >
      活动
    </AppLink>
    <AppLink
      class="nav-link"
      active-class="nav-active"
      inactive-class="nav-inactive"
      to="/aboutUs"
    >
      公司介绍
    </AppLink>
  </div>
</template>

<script>
import AppLink from '@/components/AppLink.vue'
export default {
  name: 'Nav',
  components: {
    AppLink
  }
}
</script>

<style scoped lang="less">
@import url("@/variable.less");
.nav-link {
  display: inline-block;
  height: @header-height;
  line-height: @header-height;
  font-size: 16px;
  font-weight: 400;
  color: #333;
  margin-right: 56px;
  padding: 0 4px;
}
.nav-active {
  color: #2223eb;
  position: relative;
  font-weight: bold;
  &::after {
    content: " ";
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #2223eb;
  }
}
.nav-inactive:hover {
  color: #2223eb;
}
</style>
