<template>
  <div class="header">
    <nav class="navBar">
      <div class="navContainer">
        <AppLink class="logo" to="/">
          <div class="nav-logo">
            <img src="../assets/logo.jpg" alt="Nav logo" class="nav-logo-img" />
            <div class="nav-logo-dec">
              <div class="nav-logo-title">山海图</div>
              <div class="nav-logo-subTitle">Shan Hai Map</div>
            </div>
          </div>
        </AppLink>
        <Nav />
        <div class="nav-right">
          <div
            :class="`nav-right-search ${
              isActive ? 'nav-right-search-active' : ''
            }`"
            @mouseover="mouseover"
            @mouseout="mouseleave"
          >
            <i class="iconfont icon-sousuo search-icon"></i>
            <span class="search-icon-text">搜索</span>
            <div class="search-pannel">
              <div class="search-pannel-container">
                <Search
                  ref="headerSearch"
                  @search="goSearch"
                  @onCompositionStart="onCompositionStart"
                  @onCompositionEnd="onCompositionEnd"
                />
              </div>
            </div>
          </div>
          <Select
            :options="countrys"
            :selected="currentCountry"
            @updateOption="methodToRunOnSelect"
            :closeOnOutsideClick="true"
          />
        </div>
      </div>
    </nav>
    <div class="headerPlaceholder"></div>
  </div>
</template>

<script>
import AppLink from '@/components/AppLink.vue'
import Nav from '@/components/Nav.vue'
import Select from '@/components/Select.vue'
import Search from '@/components/Search.vue'
import { mapState } from 'vuex'

export default {
  name: 'Header',
  components: {
    AppLink,
    Nav,
    Select,
    Search
  },
  data() {
    return {
      isActive: false,
      isComposing: false
    }
  },
  computed: mapState({
    countrys: state => state.countrys,
    currentCountry: state => state.currentCountry
  }),
  watch: {
    isActive(val) {
      console.log("val", val)
      //   if (val) {
      //     document.body.style.overflow = 'hidden'
      //   } else {
      //     document.body.style.overflow = 'auto'
      //   }
    }
  },
  mounted() { },
  created() {
  },
  methods: {
    onCompositionStart() {
      this.isComposing = true
    },
    onCompositionEnd() {
      this.isComposing = false
    },

    mouseover() {
      clearTimeout(this.timer)
      this.isActive = true
    },
    mouseleave() {
      clearTimeout(this.timer)
      if (this.isComposing) return
      this.timer = setTimeout(() => {
        this.isActive = false
      }, 300)
    },
    goSearch(searchFlag, keyword) {
      this.$router.push({ path: `/home/search?searchFlag=${searchFlag}&keyword=${keyword}` }).catch(error => {
        if (
          error.name !== 'search' &&
          !error.message.includes('Avoided redundant navigation to current location')
        ) {
          console.log(error)
        }
      }).finally(() => {
        this.$refs.headerSearch.reset()
      })
    },
    methodToRunOnSelect(record) {
      this.$store.commit('setCurrentCountry', record)
      this.$nextTick(() => {
        window.open('/', '_self')
      })
    }
  }
}
</script>

<style scoped lang="less">
@import url("@/variable.less");
.nav-right {
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-pannel {
  position: fixed;
  top: 72px;
  left: 0;
  right: 0;
  z-index: 2;
  display: none;
  transition: opacity 0.5s;
  .search-pannel-container {
    width: 100%;
    height: 180px;
    background-color: rgba(255, 255, 255, 0.96);
    box-shadow: 0 10px 10px 2px rgb(0 0 0 / 5%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.nav-right-search {
  line-height: 70px;
  padding-right: 50px;
  padding-left: 50px;
  position: relative;
  .search-icon {
    color: #666;
    font-size: 20px;
  }
  .search-icon-text {
    margin-left: 5px;
    font-size: 15px;
    color: #666;
    vertical-align: top;
  }
  &.nav-right-search-active {
    .search-pannel {
      display: block;
      opacity: 1;
    }
    .search-icon,
    .search-icon-text {
      color: #294ff8;
    }
  }
  .nav-search-container {
    width: 220px;
    height: 40px;
    background: #f7f7f7;
    border: 1px solid #bababa;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    .search-icon {
      margin-top: 0;
      margin-right: 8px;
    }
    .nav-search-input {
      outline: none;
      border: none;
      background: transparent;
      font-size: 13px;
      flex: 1;
      &::palceholder {
        font-size: 10px;
        color: #bababa;
      }
    }
  }
}

.modal-body {
  padding: 20px;
  text-align: center;
  font-size: 25px;
}
.modal-footer {
  height: 70px;
  border-top: 1px #dedede solid;
  padding: 0 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .modal-button {
    cursor: pointer;
    background-color: #294ff8;
    height: 50px;
    line-height: 50px;
    border-radius: 5px;
    padding: 0 30px;
    color: #fff;
    &:last-child {
      background-color: #ff0000;
      margin-left: 10px;
    }
  }
}
.navBar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  background-color: #fff;
  height: @header-height;
  box-shadow: 0 5px 12px rgb(0 0 0 / 15%);
}
.headerPlaceholder {
  width: 100%;
  height: 72px;
}
.navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: @base-width;
  margin: auto;
  height: inherit;
}
.nav-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #294ff8;
}
.nav-logo-img {
  width: 35px;
  height: 40px;
}
.nav-logo-dec {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 7px;
}
.nav-logo-title {
  letter-spacing: 6px;
  font-size: 26px;
  font-weight: bolder;
  font-style: oblique;
}
.nav-logo-subTitle {
  font-size: 12px;
  letter-spacing: 1px;
}
.search-fade-enter-active,
.search-fade-leave-active {
  transition: opacity 0.5s;
}
.search-fade-enter, .search-fade-leave-to /* .search-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
