export const getUrlParam = (url) => {
  const obj = {}
  const arr = url.split('?')
  if (arr.length > 1) {
    const params = arr[1].split('&')
    params.map(v => (obj[v.split('=')[0]] = v.split('=')[1]))
  }
  return obj
}
// 富文本标签替换
export const mpHtmlReplace = str => {
  if (typeof str !== 'string') return ''
  str = str.replace(/svg|foreignobject/g, 'section').replace('style="display: grid;"', '')

  let reg = /<img.*?(style=".*?"){0,1}\/>/g
  str = str.replace(reg, function ($1, $2) {
    if ($1.indexOf("style=") > -1) {
      return $1
    } else {
      return $1.replace(/\/>/g,'style="display:block;vertical-align:bottom;"/>')
    }
  })

  return str
}