<template>
  <div :class="`search-area ${!showSelect ? 'search-area-noSelect' : ''}`">
    <div class="search-select" v-show="showSelect">
      <div class="search-select-warper">
        <span class="search-selected-text">{{ activeText }}</span>
        <i class="iconfont icon-xialajiantouxiao search-select-icon"></i>
        <div class="search-select-box">
          <span
            v-for="item in selectData"
            :key="item.id"
            :class="`search-select-box-item ${
              active === item.id ? 'active' : ''
            }`"
            @click="selectItem(item.id, item.text)"
          >
            {{ item.text }} <i class="iconfont icon-duigou"></i>
          </span>
        </div>
      </div>
    </div>
    <div class="search-line" v-show="showSelect"></div>
    <input
      type="text"
      class="search-input"
      v-model="keyword"
      placeholder="请输入您要查找的内容"
      maxlength="30"
      @compositionstart="onCompositionStart"
      @compositionend="onCompositionEnd"
      @keyup.enter="search"
    />
    <i class="iconfont icon-sousuo search-input-icon" @click="search"></i>
  </div>
</template>

<script>
export default {
  name: 'Search',
  props: {
    showSelect: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectData: [{ id: 'home', text: '全部' }, { id: 'products', text: '服务' }, { id: 'activitys', text: '活动' }, { id: 'articles', text: '文章' }],
      active: 'home',
      activeText: '全部',
      keyword: ''
    }
  },
  mounted() {
    console.log(this.$route, '当前路由')
  },
  methods: {
    onCompositionStart() {
      this.$emit('onCompositionStart')
    },
    onCompositionEnd() {
      this.$emit('onCompositionEnd')
    },
    selectItem(active, activeText) {
      this.active = active
      this.activeText = activeText
    },
    search() {
      if (this.showSelect) {
        this.$emit('search', this.active, this.keyword)
      } else {
        this.$emit('search', this.keyword)
      }
    },
    reset() {
      this.active = 'home'
      this.activeText = '全部'
      this.keyword = ''
    },
    set(active, keyword) {
      const selectedItem = this.selectData.find(item => item.id === active)
      this.active = selectedItem.id
      this.activeText = selectedItem.text
      this.keyword = keyword
    }
  }
}
</script>

<style scoped lang="less">
.search-area-noSelect {
  width: 450px !important;
}
.search-area {
  width: 550px;
  height: 50px;
  border-radius: 6px;
  border: 1px solid #294ff8;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1px;
  &:hover {
    box-shadow: 0 0 0 4px rgb(48 123 248 / 20%);
  }
  .search-select {
    position: relative;
    padding-left: 10px;
    height: 50px;
    line-height: 50px;
    &:hover {
      .search-select-icon {
        position: absolute;
        transform: rotate(180deg);
      }
      .search-select-box {
        display: flex !important;
      }
    }
    .search-select-warper {
      cursor: pointer;
      color: #333;
      font-size: 15px;
      padding-right: 10px;
      .search-selected-text {
        padding-right: 10px;
      }
      .search-select-icon {
        font-weight: bold;
        font-size: 14px;
        position: absolute;
        top: 0px;
        transition: all 0.3s;
      }
      .search-select-box {
        position: absolute;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 240px;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 0 10px 2px rgb(0 0 0 / 10%);
        padding: 10px 20px;
        left: -10px;
        top: 50px;
        display: none;
        .search-select-box-item {
          width: 90px !important;
          line-height: 40px;
          cursor: pointer;
          color: #888;
          &.active,
          &.active i {
            color: #294ff8 !important;
          }
          i {
            color: #fff;
            font-weight: bold;
          }
          &:hover {
            color: #333;
          }
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }
  .search-line {
    margin-left: 20px;
    margin-right: 20px;
    width: 1px;
    height: 12px;
    background-color: #d3d8db;
  }
  input {
    width: 420px;
    height: 30px;
    line-height: 30px;
    border: none;
    background-color: rgba(255, 255, 255, 0);
    outline: none;
  }
  .search-input-icon {
    position: absolute;
    right: 10px;
    font-weight: 700;
    font-size: 24px;
    padding-left: 10px;
    padding-right: 10px;
    color: #333;
    cursor: pointer;
  }
}
</style>
