<template>
  <div class="card" :style="{ width }" @click="onClick(content)">
    <div class="card-img">
      <div class="hot" v-if="content.recommend && !tag">
        <span class="hot-tag">HOT</span>
      </div>
      <img :src="content.thumbUrl || content.image | formatUrl" class="card-main-img" alt="" />
      <img src="../assets/article_tag.png" v-if="tag" class="card-tag" />
      <span class="card-tag-text" v-if="tag">文章</span>
    </div>
    <div class="card-content">
      <h4 class="card-content-title" :title="content.title">
        {{ content.title }}
      </h4>
      <p class="card-content-date" v-if="content.createTime">
        {{ content.createTime }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AriticleCard',
  props: {
    content: {
      type: Object,
      default: function () {
        return {}
      }
    },
    tag: Boolean,
    width: {
      type: String,
      default: function () {
        return '384px'
      }
    },
  },
  data() {
    return {
    }
  },
  methods: {
    onClick(content) {
      window.open(`/#/articles/articleDetail?id=${content.id}`)
    }
  }
}
</script>

<style scoped lang="less">
.card {
  width: 384px;
  padding: 16px 16px 20px 16px;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
    border-color: transparent;
  }
}
.card-tag {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 52px;
  height: 16px;
}
.card-tag-text {
  position: absolute;
  left: 16px;
  top: 2px;
  z-index: 2;
  transform: scale(0.8);
  transform-origin: 0 0;
  display: inline-block;
  font-size: 10px;
  color: #fff;
}
.card-img {
  width: 120px;
  height: 120px;
  margin-right: 16px;
  position: relative;
  .hot {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    color: #fff;
    font-size: 12px;
    text-align: center;
  }
  .hot-tag {
    width: 40px;
    height: 40px;
    position: relative;
    &::before {
      content: "HOT";
      position: absolute;
      overflow: hidden;
      width: 38px;
      height: 18px;
      line-height: 18px;
      top: 2px;
      left: 0;
      background-image: linear-gradient( 311deg, #DA0808 0%, #FF7448 100%);
      border-radius: 0 10px 10px 12px;
    }
  }
}
.card-main-img {
  width: 100%;
  height: 100%;
}
.card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.card-content-title {
  font-size: 18px;
  list-style: 25px;
  margin-top: auto;
  margin-bottom: auto;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
}
.card-content-date {
  font-size: 15px;
  color: #666;
}
</style>
