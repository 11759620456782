<template>
  <a v-if="isExternalLink" v-bind="$attrs" :href="to" target="_blank">
    <slot />
  </a>
  <router-link
    v-else
    v-bind="$props"
    custom
    v-slot="{ isActive, href, navigate }"
  >
    <a
      v-bind="$attrs"
      :href="href"
      @click="navigate"
      :class="isActive ? activeClass : inactiveClass"
    >
      <slot />
    </a>
  </router-link>
</template>

<script>
export default {
  name: 'AppLink',
  props: {
    to: [String, Object],
    exact: Boolean,
    activeClass: String,
    inactiveClass: String
  },
  computed: {
    isExternalLink () {
      return typeof this.to === 'string' && (this.to.startsWith('http') || this.to.startsWith('https'))
    }
  }
}
</script>
