<template>
    <div class="title">
        <slot name="before"></slot>
        <span class="text">{{title}}</span>
        <img class="img" src="../assets/title.png" alt="Title" />
        <slot name="after"></slot>
    </div>
</template>

<script>
export default {
  name: 'Title',
  props: {
    title: String
  }
}
</script>

<style scoped lang="less">
.title {
    font-size: 30px;
    font-weight: 600;
    color: #333;
    margin: auto;
    text-align: center;
    position: relative;
}
.text {
    padding-right: 8px;
}
.img {
    width: 20px;
    height: 20px;
    vertical-align: top;
}
</style>
