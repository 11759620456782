<template>
  <div class="shmap">
    <div class="shmap-container" v-if="!loading">
      <Header />
      <router-view></router-view>
      <Footer v-if="isFooterShow" />
      <GoTop />
      <notifications />
      <Modal
        title="请选择国家"
        width="640px"
        v-if="showModal"
        @close="cancelModal"
      >
        <div slot="body">
          <div class="country-container">
            <div
              :class="`country-item ${
                country.tenantId === selectedTenantId ? 'active' : 0
              }`"
              v-for="(country, idx) in countrys"
              :key="idx"
              @click="selectCountry(country)"
            >
              <img
                :src="country.logo | formatUrl"
                class="country-icon"
                alt=""
              />
              <div class="country-text">{{ country.countryAbbreviation }}</div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
    <div v-if="error" class="shamp-error">
      <img src="./assets/empty.png" />
      <p>获取国家信息失败，无法正常进入页面！</p>
      <br />
      <p>接口/app/country/list请求错误</p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Vue from 'vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Modal from '@/components/Modal.vue'
import GoTop from '@/components/GoTop.vue'
import { asyncLoading } from 'vuejs-loading-plugin'
import * as api from '@/api'
import { DEFAULT_TENANT_ID } from '@/utils/contants'
import { getUrlParam } from '@/utils'
import { visibilitychangeFun } from '@/utils/verChange'
export default {
  name: 'App',
  components: {
    Header,
    Footer,
    GoTop,
    Modal
  },
  data() {
    return {
      loading: true,
      showModal: false,
      countrys: [],
      error: false,
      selectedTenantId: DEFAULT_TENANT_ID,
      isFooterShow: true
    }
  },
  watch: {
    '$route'(to, from) {
      if (to.path === '/aboutUs') {
        this.isFooterShow = false
      } else {
        this.isFooterShow = true
      }
    }
  },
  created() {
    this.$loading(true)
    this.loading = true
    asyncLoading(new Promise((resolve, reject) => {
      api.fetchCountry({ state: 0, pageSize: 100 }).then(res => {
        if (res.success) {
          console.log(res, '国家列表')
          const { result } = res
          const { records = [] } = result
          this.countrys = records
          this.$store.commit('setCountrys', records)
          const currentCountry = Vue.ls.get('CURRENT_COUNTRY')
          const urlParam = getUrlParam(location.href)
          let recordFromUrlParam
          if (urlParam.country) {
            recordFromUrlParam = records.find(item => item?.code === urlParam.country.toUpperCase())
          }
          if (recordFromUrlParam) {
            this.$store.commit('setCurrentCountry', recordFromUrlParam)
          } else {
            if (!currentCountry) {
              const record = records.find(item => item?.tenantId === DEFAULT_TENANT_ID)
              this.$store.commit('setCurrentCountry', record)
              this.showModal = true
            } else {
              this.$store.commit('setCurrentCountry', currentCountry)
            }
          }
          resolve()
        } else {
          reject(res.message)
        }
      }).catch(err => {
        reject(err.message)
      })
    })).then(() => {
      this.$loading(false)
      this.loading = false
      // api.verifyToken()
    }).catch((error) => {
      console.error(error)
      this.error = true
      this.$notify({ title: '', text: error, type: 'warn' })
    })

    // 检测项目部署，自动更新
    visibilitychangeFun(() => window.location.reload(true))
  },
  methods: {
    cancelModal() {
      this.showModal = false
    },
    selectCountry(country) {
      this.$store.commit('setCurrentCountry', country)
      this.selectedTenantId = country.tenantId
      this.$nextTick(() => {
        window.location.reload()
        // window.open('/', '_self')
      })
    }
  }
}
</script>

<style scoped lang="less">
@import url("@/variable.less");
.shmap-container {
  font-family: @font-family;
  min-width: @base-width;
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.shamp-error {
  min-width: @base-width;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0;
  p {
    margin-top: 20px;
  }
}
.country-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row wrap;
  padding: 40px 10px;
}
.country-item {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 0 33.333333333%;
  margin-bottom: 20px;
  &.active {
    .country-text {
      color: #ee0000;
    }
  }
}
.country-text {
  color: #333;
  margin-top: 10px;
}
.country-icon {
  width: 80px;
  height: 53px;
  box-sizing: border-box;
  border: 1px solid #eee;
}
</style>
