import CryptoJS from 'crypto-js'

export default {
  // 加密
  encrypt(text) {

    const encrypted = CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  },
  decrypt(ciphertext) {
    const decrypted = CryptoJS.AES.decrypt(ciphertext, CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}
